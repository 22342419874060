
import React from 'react'
import SectionBlock from '../../components/sectionBlock'


export default function ContactSection() {
    return (
        <section id="contactSection">
            <div className="section columnSection">
                <SectionBlock title="Adres" text={["Cieszyńska 6 Piętro 1, Singers","Warszawa 02-716"]} />
                <SectionBlock title="Kontakt" text={["singersrezerwacje@gmail.com","Tel: +48 535 478 687"]} />
                <SectionBlock title="Godziny Otwarcia" text={
                    [
                        "pt:  18:00 - 3:00"
                        , "sb:  18:00 - 3:00"
                    ]} />   
            </div>    
        </section>
    )
}
