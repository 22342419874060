import React from 'react';
import Logo from '../../components/logo/index'
import Navbar from '../../components/navbar/index'


export const LandingSection = () => {
    return (
        <section id="landingSection">
            <div id="darkOverlay">
                <Navbar />
                <span style={{display: 'flex', margin: '15vh' }}></span>
                <Logo />
            </div>
            
        </section>
    )
}

export default LandingSection
