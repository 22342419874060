
import React, {useState,useEffect} from 'react'

export default function Navbar() {
    
    const [toggleMenu, setToggleMenu] = useState(false)
    const [toggleMenuClass, setToggleMenuClass] = useState("")
    const [screenWidth, setScreenWidth] = useState(window.innerWidth)

    const toggleNav = (event) => {
        setToggleMenu(!toggleMenu)
        setToggleMenuClass(toggleMenuClass==="active"? "" :  "active")
    }

    const scrollIntoView = (section) => {
        if (document.getElementById(section) )
            document.getElementById(section).scrollIntoView({ behavior: 'smooth', block: 'start' });
            toggleNav();
    }

    useEffect(() => {
        const changeWidth = () =>  {
            setScreenWidth(window.innerWidth)
        }

        window.addEventListener("resize", changeWidth)

        return () => {
            window.removeEventListener("resize", changeWidth)
        }

    }, [])

    return (
        <div class="navWrapper">
            <nav>
                { (toggleMenu || screenWidth > 500) && (
                    <ul>
                        <li><a onClick={() => scrollIntoView('karaokeSection')}>O&nbsp;nas</a></li>
                        <li><a onClick={() => scrollIntoView('Imprezy')}>Imprezy zamknięte</a></li>
                        <li><a onClick={() => scrollIntoView('menuSection')}>Menu</a></li>
                        <li><a onClick={() => window.location.href='/karaoke'}>Lista karaoke</a></li>
                        <li><a onClick={() => scrollIntoView('gallerySection')}>Wydarzenia</a></li>
                        <li><a onClick={() => scrollIntoView('contactSection')}>Kontakt</a></li>
                        <li><a onClick={() => scrollIntoView('ruleSection')}>Regulaminy</a></li>
                    </ul>
                )}
            </nav>
            <div class="icons">
                <svg
                    width="65"
                    height="65"
                    viewBox="0 0 32 42"
                    xmlns="http://www.w3.org/2000/svg"
                    onClick={toggleNav}
                    className={toggleMenuClass}
                >
                    <g transform="matrix(1,0,0,1,-389.5,-264.004)">
                        <g id="arrow_left2">
                            <g transform="matrix(1,0,0,1,0,5)">
                            <path
                                id="top"
                                d="M390,270L420,270L420,270C420,270 420.195,250.19 405,265C389.805,279.81 390,279.967 390,279.967"
                            />
                            </g>
                            <g
                            transform="matrix(1,1.22465e-16,1.22465e-16,-1,0.00024296,564.935)"
                            >
                            <path
                                id="bottom"
                                d="M390,270L420,270L420,270C420,270 420.195,250.19 405,265C389.805,279.81 390,279.967 390,279.967"
                            />
                            </g>
                            <path id="middle" d="M390,284.967L420,284.967" />
                        </g>
                        </g>
                </svg>
            </div>
        </div>
    )
}
    