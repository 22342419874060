
import React from 'react'

//import { ReactPhotoCollage } from 'react-photo-collage'

const setting = {
  width: '100%',
  height: ["400px"],
  layout: [4],
  photos: [
    {
      source: '/picture/gallery/1.jpg',
    },
    {
      source: '/picture/gallery/2.jpg',
    },
    {
      source: '/picture/gallery/3.jpg',
    },
    {
      source: '/picture/gallery/4.jpg',
    },
  ]
};

export default function KaraokeSection() {
  return (
    <section id="karaokeSection">
      <div className="section">
        <div className="sectionCol jcc aic tac pr30 mb30 karaokeSectionLeft">

          <h1>Witaj w Singers</h1>
          <p>W klubie muzycznym, gdzie dźwięki spotykają się z magią, tworząc niezapomniane chwile. Nasza przestrzeń ożywa w rytmie muzyki, a atmosfera pełna jest energii i radości. </p>
          <p><b>Niezależnie od okazji</b> - urodzin, rocznic czy spotkań firmowych - <b>zapewniamy niepowtarzalny klimat i profesjonalną obsługę, aby Twoja impreza była wyjątkowa</b>. Przygotujemy dla Ciebie spersonalizowany plan, dopasowany do Twoich potrzeb i preferencji muzycznych. Dzięki magicznej melodii <b>Twoje wydarzenie stanie się niezapomnianym przeżyciem dla Ciebie i Twoich gości.</b> </p>
          <p><b>Odkryj magię muzyki</b> w naszym klubie i zorganizuj wyjątkową imprezę już dziś! Znajdujemy się na górnym Mokotowie, 3 minuty piechotą od Metra Wilanowska.<b> Posiadamy profesjonalny sprzęt</b>, scenę oraz nagłośnienie, które zadowoli każdego! Do dyspozycji ponad 160m2 przestrzeni dla Ciebie i Twoich gości. Serdecznie zapraszamy!</p>
        </div>
        <div className="sectionCol">
          { 
            //<ReactPhotoCollage {...setting} />
          }
        </div>
      </div>
    </section>
  )
}
