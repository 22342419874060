import React from "react";
import ContactSection from "./contactSection";
import GallerySection from "./gallerySection";
import LandingSection from "./landingSection";
import MenuSection from "./menuSection";
import KaraokeSection from "./karaokeSection"
import RuleSection from "./ruleSection";
import EventSection from "./eventSection";

export function Homepage(props){
    return (
        <div id="homePage">
            <LandingSection />
            <KaraokeSection />
            <EventSection />
            <MenuSection />
            <GallerySection />
            <ContactSection />
            <RuleSection />
            <div className='pfr'>
                <div>
                    <img src='/picture/pfr/plansza_informacyjna_PFR_poziom.jpg' alt='' />
                </div>
            </div>
        </div> 
    )
}