import React from 'react'

export default function RuleSection() {
    return (
        <section id="ruleSection">
            <div className="section" style={{ flexDirection: 'column' }}>
                <h1>Regulamin restauracji</h1>

                <p><u>Każdy z gości restauracji muzycznej Singers jest zobowiązany do zapoznania się i przestrzegania niniejszego regulaminu. Przebywanie na terenie restauracji jest jednoznaczne z przyjęciem warunków niniejszego regulaminu.</u></p>

                <ol>
                    <li>Na terenie restauracji można spożywać posiłki oraz napoje (w tym napoje alkoholowe) zakupione wyłącznie w restauracji muzycznej Singers.</li>
                    <li>Osób nietrzeźwych nie obsługujemy.</li>
                    <li>W restauracji muzycznej Singers obowiązuje bezwzględny ZAKAZ PALENIA wyrobów tytoniowych.</li>
                    <li>Za dzieci przebywające na terenie restauracji odpowiadają rodzice lub opiekunowie. Za dzieci pozostawione bez opieki restauracja nie ponosi żadnej odpowiedzialności.</li>
                    <li>Za rzeczy pozostawione bez opieki restauracja muzyczna Singers nie odpowiada.</li>
                    <li>Obsługa może nie wpuścić/wyprosić z Restauracji muzycznej Singers każdą osobe bez podania konkretnej przyczyny. Zakaz ten będzie szczególnie stosowany wobec: - osób zachowujących się wulgarnie w stosunku do obsługi i osób trzecich - osób będących pod wpływem alkoholu, narkotyków i innych środków odurzających -osób usypiających przy stolikach/barze - osób , które mimo zakazu próbują wnieść oraz spożywać własne napoje, napoje alkoholowe, dania, desery i inne artykuły spożywcze - osób mających zakaz wstępu do Restauracji Zakaz ten będzie stosowany wobec wszystkich osób łamiących regulamin Restauracji, włącznie z osobami mającymi w restauracji rezerwacje stolików, a poniesione koszty związane z rezerwacją nie zostaną zwrócone.</li>
                    <li>Służba porządkowa / ochrona w trosce o bezpieczeństwo osób przebywających w lokalu, może zarekwirować ostre narzędzia, gaz, środki pirotechniczne, alkohol itp., lub odmówić osobie posiadającej w/w rzeczy wejścia do klubu.</li>
                    <li>Cały obiekt na zewnątrz jak i wewnątrz jest monitorowany dla celów bezpieczeństwa osób oraz ochrony mienia. Materiały z nagrań są archiwizowane i udostępniane jedynie policji, prokuraturze lub dla sądu. Jeżeli osoba wchodząca do klubu nie wyraża na to zgody, wówczas nie powinna wchodzić do klubu.</li>
                    <li>Dla dobrej i bezpiecznej zabawy prosimy o współpracę z służba porządkową / ochroną.</li>
                    <li>
                        Osoba wchodząca na teren klubu ma obowiązek:
                        <ol>
                            <li>zachować się w sposób nie zagrażający bezpieczeństwu innych osób, informować pracowników/współpracowników klubu, pracowników ochrony i służb porządkowych o sytuacjach zagrożenia życia ludzkiego, zdrowia lub mienia;</li>
                            <li>stosować się do wskazań i poleceń służb porządkowych klubu podczas imprezy oraz osób odpowiedzialnych za imprezę;</li>
                            <li>kierować się do oznaczonych wyjść ewakuacyjnych i nie utrudniać dojazdu służbom ratowniczym.</li>
                            <li>przestrzegać wszelkich wymogów związanych z pandemią COVID-19, zgodnie z obowiązującymi przepisami, restauracja muzyczna Singers nie ponosi odpowiedzialności za ich łamanie.</li>
                        </ol>
                    </li>
                    <li>
                        Osobom wchodzącym do klubu zabrania się:
                        <ol>
                            <li>wchodzenia na obszary, które nie są przeznaczone dla osób nie będących pracownikami/współpracownikami klubu;</li>
                            <li>wychodzenia z zakupionym w klubie alkoholem poza teren klubu;</li>
                            <li>sprzedawania, reklamowania, akwizycji, jakichkolwiek towarów i usług oraz przeprowadzania zbiórek pieniężnych bez zgody klubu.</li>
                        </ol>
                    </li>
                    <li>Osoby nie przestrzegające ustaleń regulaminu i nie stosujące się do poleceń służb porządkowych, w szczególności osoby agresywne w stosunku do innych uczestników imprezy lub do pracowników/współpracowników klubu zostaną usunięte z klubu lub przekazane Policji.</li>
                    <li>Osoby, które dokonują aktu wandalizmu i niszczenia klubu będą zobowiązane do pokrycia kosztów usunięcia szkody, zaś w drastycznych przypadkach będą zgłaszane na policję.</li>
                    <li>Osoby sprzedające i używające narkotyki będą zatrzymane i oddane w ręce Policji. W celu pomocniczym i ułatwiającym rozpoznanie dealerów są rozmieszczone kamery, których nagrania będą niepodważalnym dowodem w sprawie.</li>
                    <li>Jeżeli według Ciebie dany pracownik klubu (barman, kelnerka, służba sprzątająca, służba porządkowa, ochrona) zachował się w niewłaściwy lub nieuczciwy sposób proszę zgłosić to menagerowi klubu lub wysłać emaila na adres singerswarszawa@gmail.com z opisem zdarzenia, opisem danego pracownika oraz przybliżoną godziną zdarzenia. Klub gwarantuje pełną dyskrecję.</li>
                    <li>Klub nie organizuje 18-stek.</li>
                    <li>W klubie obowiązuję zakaz wychodzenia ze szkłem na parkiet, scenę oraz na zewnątrz.</li>
                    <li>Obowiązuje całkowity zakaz wnoszenia szkła do toalet.</li>
                    <li>Klub informuje, że w trakcie organizowanych imprez prowadzona jest rejestracja audiowizualna wydarzeń oraz wykonywane są zdjęcia fotograficzne.</li>
                    <li>Osoby które decydują się na udział w imprezach organizowanych w klubie,zdają sobie sprawę i wyrażają zgodę na możliwość utrwalenia ich wizerunku w trakcie imprezy w formie rejestracji audiowizualnej i zdjęć fotograficznych, w tym jako osób stanowiących szczegół całości takiej jak publiczna impreza czy zgromadzenie zgodnie z przepisami ustawy o prawie autorskim i prawach pokrewnych.</li>
                    <li>Rejestracja audiowizualna oraz zdjęcia fotograficzne z imprez w klubie będą wykorzystywane wyłącznie w celu promocji klubu i jego działalności, w tym w szczególności w celu zamieszczania  ich na kontach klubu prowadzonych w social media (takich jak Facebook czy Instagram itp.) Klub oświadcza, że rejestracje audiowizualne i zdjęcia z imprez będzie wykorzystywał w sposób nie naruszający dobrego imienia osób na nich przedstawionych.</li>
                    <li>Jeżeli osoba, której wizerunek został utrwalony w ramach imprezy w klubie w formie audiowizualnej albo na zdjęciu fotograficznym nie życzy sobie wykorzystania jej wizerunku, powinna w tym celu złożyć stosowne oświadczenie (wycofania zgody lub wyrażenia sprzeciwu) na adres e-maill singerswarszawa@gmail.com lub pisemnie na adres klubu.</li>
                </ol>

                <p>Administratorem Państwa danych osobowych jest firma MG Maciej Gorczycki z siedzibą pod adresem: , Kwiatowa 23/21, 02-539 Warszawa, NIP: 9512226775 REGON: 367514571.</p>
                {/* ######################################################################################################################################################################### */}
                <h1 style={{ marginTop: "30px"}}>Regulamin rezerwacji</h1>
                <ol>
                    <p><u>Dokonanie rezerwacji w restauracji Singers (dalej Restauracji) jest jednoznaczne z zaakceptowaniem poniższego regulaminu oraz regulaminu restauracji zamieszczonego na stronie internetowej. Rekomendujemy rezerwację stolika, szczególnie w weekendy.</u></p>
                    <li><b>Warunkiem potwierdzenia rezerwacji jest wpłata zadatku w terminie nie późniejszym niż 2 dni robocze po zgłoszeniu (telefonicznym lub mailowym) chęci rezerwacji.</b> Za wpłatę zadatku uznaje się moment zaksięgowania wpływu zadatku na koncie Restauracji. W przypadku braku wpłaty zadatku w powyższym terminie, Restauracja uznaje, że Gość zrezygnował z chęci rezerwacji i uznaje rezerwację za nieistniejącą.</li>
                    <li>Zasady naliczania zadatku określonego w pkt. 1 są następujące:
                        <ul>
                            <li>Zadatek jest pobierany od wszystkich grup 4 osobowych i większych. <b>Zadatek wynosi 50 zł od osoby i jest obowiązkowy</b></li>
                            <li>Przy grupach 20 osobowych i większych wysokość zadatku ustalana jest indywidualnie.</li>
                            <li><b>Kwota wpłaconego zadatku jest do wykorzystania w całości</b>  w dniu rezerwacji na napoje, alkohol oraz dania z naszej kuchni. W przypadku wybrania opcji serwisowej z bransoletkami zadatek, bądź jego część, przechodzi na poczet serwisu.</li>
                            <li><b>Niewykorzystana kwota zadatku nie jest zwracana.</b></li>
                            <li>W przypadku wybrania opcji przygotowania dań spoza regularnego menu (czyli tzw. <b>cateringu</b>), wymagane jest zgłoszenie i ustalenie cateringu na minimum 7 dni przed dniem rezerwacji. Konieczne jest także opłacenie zamówienia cateringowego (zaksięgowanie na koncie Restauracji) w całości w terminie najpóźniej na 7 dni przed dniem rezerwacji. W przypadku chęci zamówienia cateringu w terminie krótszym niż na 7 dni przed rezerwacją, warunki przygotowania i płatności cateringu są ustalane indywidualnie.</li>
                        </ul>
                    </li>
                    <li>Zadatek można wpłacać w jeden z poniższych sposobów:
                        <ul>
                            <li><b>Blikiem</b> na numer telefonu restauracji, tj. 508 854 655, z odbiorcą MG Maciej Gorczycki</li>
                            <li><b>Przelewem</b> na konto: MG Maciej Gorczycki, nr  23 1090 1870 0000 0001 5619 7151.</li>
                            <li>W tytule Blika lub przelewu proszę podać datę rezerwacji, ilość osób oraz imię i nazwisko/nazwę firmy.</li>
                        </ul>
                    </li>
                    <li>Warunki odwołania rezerwacji i zwrotów:
                        <ul>
                            <li>W przypadku odwołania rezerwacji z wyprzedzeniem większym niż <b>14 dni</b> kalendarzowych, wpłacony zadatek podlega zwrotowi w całości.</li>
                            <li>W przypadku odwołania rezerwacji z wyprzedzeniem większym niż <b>7 dni</b> kalendarzowych (ale mniejszym niż 21 dni), zwrotowi podlega <b>50%</b> wpłaconej kwoty zadatku.</li>
                            <li><b>W przypadku odwołania rezerwacji z wyprzedzeniem mniejszym niż 7 dni kalendarzowych, zadatek nie jest zwracany</b> i jest traktowany jako opłata za usługę rezerwacji stolika, która ma pokryć stratę Restauracji z tytułu wyłączenia stolika ze sprzedaży.</li>
                            <li>W przypadku złożenia zamówienia na catering i odwołania rezerwacji w momencie, gdy produkty na dania cateringowe zostały już zamówione, wpłacona opłata za catering nie jest zwracana z uwagi na poniesione przez restaurację koszty.</li>
                        </ul>
                    </li>
                    <li>Jeśli chcą Państwo dokonać rezerwacji z wyprzedzeniem czasowym mniejszym niż 24 godziny, prosimy dokonywać ich telefonicznie pod numerem telefonu 535 478 687. Restauracja nie może zagwarantować dokonania rezerwacji przez wiadomość/mail wysłaną później niż 24 godziny przed rozpoczęciem rezerwacji.</li>
                    <li>Zarezerwowanie stolika jest możliwe najpóźniej na godzinę 21.</li>
                    <li>Gwarantujemy dostępność zarezerwowanego stolika przez 15 minut po czasie, na który została dokonana rezerwacja. Bardzo prosimy o informację telefoniczną o możliwym późniejszym przybyciu z 24-godzinnym wyprzedzeniem na numer telefonu 535 478 687. Brak takiej informacji oznacza, że restauracja nie gwarantuje dostępności stolika po upływie wyżej wymienionego czasu.</li>
                    <li>Uprzejmie prosimy o odwoływanie rezerwacji, o których wiadomo, że nie zostaną wykorzystane.</li>
                    <li>Restauracja nie gwarantuje dostępności większej ilości miejsc niż ustalone i potwierdzone podczas dokonywania rezerwacji.</li>
                    <li>W przypadku przybycia mniejszej ilości gości niż ustalona, restauracja ma prawo po 30 minutach do anulowania rezerwacji w części proporcjonalnie do ilości brakujących gości poprzez zmniejszenie liczby stolików oraz liczby miejsc o liczbę brakujących gości.</li>
                    <li>Ze względu na komfort i sprawną obsługę gości, w przypadku rezerwacji powyżej 8 osób polecamy ustalić menu wcześniej, minimum tydzień przed rezerwacją.</li>
                    <li>Istnieje możliwość wniesienia własnego tortu. Wiąże się to z opłatą 50zł, która obejmuje race, wyniesienie tortu wraz z jego pokrojeniem i podaniem oraz wspólne odśpiewanie sto lat. Torty są wynoszone około godziny 23:00.</li>
                    <li>W dniu rezerwacji można przyjść wcześniej (jesteśmy otwarci od godziny 18), by udekorować salę. Nie akceptujemy tylko konfetti oraz wszelkich ozdób/rzeczy które je zawierają. Warunkiem wniesienia dekoracji jest zabranie ich po zakończeniu imprezy.</li>
                    <li>Do grup powyżej 5 osób doliczana jest opłata za serwis w wysokości 10 % od kwoty końcowej rachunku.</li>
                    <li>
                        Istnieją tylko dwie możliwości uiszczenia opłaty serwisowej:
                        <ol style={{ listStyleType: 'lower-alpha' }}>
                            <li>Podczas regulowania utworzonego <b>jednego rachunku dla grupy</b>, do którego na koniec doliczane jest 10% serwisu. <b>W tym przypadku nie ma możliwości dzielenia rachunku na poszczególne osoby z grupy.</b> Organizator lub osoba dokonująca rezerwacji lub inny uczestnik rezerwacji jest zobowiązany do uregulowania całego rachunku grupy (oczywiście może potem już we własnym zakresie rozliczyć się z innymi osobami z grupy).</li>
                            <li><b>Wcześniejsze uiszczenie opłaty serwisowej</b> tj. 15 zł/os (możemy przyjąć że zadatek przechodzi na poczet serwisu) i wtedy zapewniamy Państwu bransoletki oraz możliwość rozliczania się na bieżąco przy barze bez otwierania rachunku.</li>
                        </ol>
                    </li>
                    <li>W przypadku, gdy na rachunku końcowym pozostaną nierozliczone pozycje, odpowiedzialność za pełne uregulowanie rachunku ponosi organizator rezerwacji lub osoba która dokonywała rezerwacji.</li>
                </ol>
            </div>
        </section>
    )
}