
import React from 'react'

export default function SectionBlock(props) {
    return (
        <div className="sectionBlock">
            <h2>{props.title}</h2>
            {
                
                props.text.map((name,index) => 
                {
                    return <p>{name}</p>
                })
                
            }
        </div>
    )
}
