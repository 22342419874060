
import React from 'react'

export default function MenuSection() {
    return (
        <section id="menuSection">
            <div className="section">
                <div className="sectionCol" style={{ alignItems: 'center' }}>
                  <h3>Menu (wersja polska)</h3>
                  <a href="picture/menu/menuFoodPL.jpg" target="_blank">
                    <img style={{width: '100%', maxWidth: '500px', minWidth: '200px'}} src="picture/menu/menuFoodPL.jpg" alt="menu" />
                  </a>
                </div>
                <div className="sectionCol" style={{ alignItems: 'center' }}>
                  <h3>Drinki (wersja polska)</h3>
                  <a href="picture/menu/menuDrinksPL.jpg" target="_blank">
                    <img style={{width: '100%', maxWidth: '500px', minwidth: '200px'}} src="picture/menu/menuDrinksPL.jpg" alt="menu" />
                  </a> 
                  <p>* kliknij aby powiekszyc</p>
                </div>
                
                <div className="sectionCol" style={{ alignItems: 'center', marginTop: '100px' }}>
                  <h3>Menu (English)</h3>
                  <a href="picture/menu/menuFoodEN.jpg" target="_blank">
                    <img style={{width: '100%', maxWidth: '500px', minWidth: '200px'}} src="picture/menu/menuFoodEN.jpg" alt="menu" />
                  </a>
                </div>
                <div className="sectionCol" style={{ alignItems: 'center', marginTop: '100px' }}>
                  <h3>Drinks (English)</h3>
                  <a href="picture/menu/menuDrinksEN.jpg" target="_blank">
                    <img style={{width: '100%', maxWidth: '500px', minwidth: '200px'}} src="picture/menu/menuDrinksEN.jpg" alt="menu" />
                  </a> 
                  <p>* click to zoom in</p>
                </div>
               
            </div>
        </section>
    )
}
